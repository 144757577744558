export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      title: 'Erro | UnicPages.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/criar/:hotlinkTemplate',
    name: 'criar',
    component: () => import('../components/app/criar/Criar.vue'),
    meta: {
      logged: true,
      title: 'Crie e Edite seu site | UnicPages.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/sites',
    name: 'sites',
    component: () => import('../components/app/sites/Sites.vue'),
    meta: {
      logged: true,
      title: 'Seus sites | UnicPages.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sites-compartilhados',
    name: 'sitesExtras',
    component: () => import('../components/app/sitesExtras/SitesExtras.vue'),
    meta: {
      logged: true,
      title: 'Sites Compartilhados | UnicPages.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/planos',
    name: 'planos',
    component: () => import('../components/app/planos/Planos.vue'),
    meta: {
      logged: true,
      title: 'Planos Exclusivos | UnicPages.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/reativar',
    name: 'reativar',
    component: () => import('../components/app/reativar/Reativar.vue'),
    meta: {
      logged: true,
      title: 'Reativar Assinatura | UnicPages.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../components/app/templates/Templates.vue'),
    meta: {
      logged: true,
      title: 'Templates Prontos | UnicPages.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/afiliados',
    name: 'afiliados',
    component: () => import('../components/app/afiliados/Afiliados.vue'),
    meta: {
      logged: true,
      title: 'Afiliados | UnicPages.com',
      layout: 'navbar-layout'
    }
  }
]
